footer {
  &.footer {
    background: $color-dark;
    padding: 40px 0;
    text-align: center;

    .copyright {
      color: $font-color;
      font-size: $font-size-smaller;
    }

    &.light {
      background: $color-light;
      border-top: solid 1px rgba(0, 0, 0, 0.05);
    }
  }
}