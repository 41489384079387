/*=================================================================*/
/*                      TYPOGRAPHY                              
/*=================================================================*/

.bcardtext {
  color:black;
  font-family: 'garamond classico';
  font-variant: small-caps;
  font-size: 18px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.marker {
  color: $color-dark;
  font-family: "Permanent Marker", sans-serif;
  font-weight: 600;
  font-style: normal;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.name {
  color: $color-dark;
  font-family: "Bungee Shade", sans-serif;
  font-weight: 600;
  font-style: normal;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

h1, h2, h3, h4, h5, h6 {
  color: $color-dark;
  font-family: $font-family;
  font-weight: $font-bold;
  margin: 10px 0;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

h1 {
  font-size: $font-size-largest;
}

h2 {
  font-size: $font-size-xx-large;
}

h3 {
  font-size: $font-size-x-large;
}

h4 {
  color: $color-dark;
  font-size: $font-size-larger;
}

a {
  color: black;
  outline: 0;
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  &:hover {
    color: $color-primary;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

blockquote {
  padding: 20px 20px;
  margin: 0 0 20px;
  font-size: $font-size-normal;
  background: #ffb681;
  border-radius: 10px;
}

blockquote p {
  line-height: 1.6;
}

/* === Pre === */
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #ffb681;
  border: 1px solid #ccc;
  border-radius: 0;
}

li span {
  display:inline;
  margin-left: -15px;
}