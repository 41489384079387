.box {
  background-color: $color-offwhite;
  border: 15px solid $color-white;
  position:relative;
}
#aboutme{
  height: 400px;
  width: 620px;
}

.card {
  background-color: $color-white;
  position:relative;
  height: 200px;
  width: 350px;
  text-align: center;
}

.triangle {
  border-style: solid;
  border-width: 0 550px 300px 0;
  border-color: transparent #fca8a0 transparent transparent;
  position: absolute; 
  top: 0px;
  right: 0px;
  opacity:25%;
}

.chessboard {
  border: 15px solid $color-dark;
  background: 
  repeating-conic-gradient(#769656 0% 25%, #EEEED2 0% 50%) 
    50% / 80px 80px;
  height: 320px;
  width: 320px;
}

.pieceholder {
  background-color: $color-offwhite;
  border: 15px solid $color-dark;
  position:relative;
  height: 80px;
  width: 320px;
}