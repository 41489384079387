// FONTS
$font-family: "Rubik", sans-serif;

$font-size-smaller: 14px;
$font-size-normal: 16px;
$font-size-larger: 18px;
$font-size-x-large: 24px;
$font-size-xx-large: 28px;
$font-size-largest: 36px;

$font-color: #ffb681;

$font-bold: 700;

// COLORS
$color-light: #3e3e3e;
$color-dark: #0c1a25;
$color-white: #F2F3F4;
$color-offwhite: #f1e1d5;
$color-active: #CC5500;
$color-primary: #7bafd4;